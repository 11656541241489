@import "./vars";
@import "./mixins/type";
@import "./normalize";
@import "./custom";
@import "./animation";
@import url('https://fonts.googleapis.com/css2?family=Univers+Next+Pro&display=swap');

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format('truetype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Regular"),local("Poppins-Regular"),url(./poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Regular"),local("Poppins-Regular"),url(./poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Poppins Medium"),local("Poppins-Medium"),url(./poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Poppins Medium"),local("Poppins-Medium"),url(./poppins/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Poppins SemiBold"),local("Poppins-SemiBold"),url(./poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Poppins SemiBold"),local("Poppins-SemiBold"),url(./poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  font: normal 1em/#{1.25} $font-primary;
}

body {
  font: normal 1em/#{1.25} $font-primary;
  background: $color-background;
}

#root {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

// Basic typography
::placeholder {
  color: $color-black60;
}

a,
.link {
  color: $color-button-primary-hover;
  text-decoration: none;
  cursor: pointer;
  padding: 7px 12px;

  &:hover {
    color: #025049;
    background-color: #6542BE08;
    text-decoration: none;
  }
}

p {
  margin: 0 0 1rem;
}

.nobr {
  white-space: nowrap;
}

.text_align_left {
  text-align: left;
}

.text_align_right {
  text-align: right;
}

.text_align_center {
  text-align: center;
}

.color_black40 {
  color: $color-black40;
}

.color_black60 {
  color: $color-black60;
}


.color-black {
  color: $color-black;
}

.color-black40 {
  color: $color-black40;
}

.color-black60 {
  color: $color-black60;
}

.color-link {
  color: $color-button-primary-hover;
}

.color-highlight {
  color: $color-yellow;
}

.color-danger {
  color: $color-red;
}

.min-height-100 {
  height: 100%;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.iti.iti--allow-dropdown.iti--show-flags.iti--inline-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0;
  border: 0;
  padding-left: 0;
  height: 45px;
}

input.iti__tel-input {
  border: none;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding: 0 !important;
  cursor: text;
  direction: ltr;
  flex-grow: 2;
  margin: 0 0 0 0.3rem !important;
}

.iti__flag-container {
  width: 5.6rem;
  height: 2.5rem;
  position: relative;
  box-sizing: border-box;
}

input.iti__tel-input {
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &:focus-visible {
    outline: none;
  }
}

span.input-phone__label-border-en {
  text-align: justify;
}

.iti--show-selected-dial-code .iti__selected-flag {
  background-color: transparent;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti {
  display: flex !important;
  background-color: #f0f2f2;
  height: 4rem !important;
  border-radius: 0.5rem !important;
}

.iti__tel-input {
  background-color: transparent;
}

.underline-link-text {
  color: #31A18B;
  cursor: pointer;
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
}

.underline-link-text:hover {
  text-decoration: underline;
}
