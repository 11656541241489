$grid-gutter: 24px;

// Colors
$color-red: #FF4D4A;
$color-bittersweet: #FE6B5F;
$color-green: #006666;
$color-yellow: #FAE160;
$color-mellow: #F2F0EB;
$color-violet: #31A18B;
$color-violet-hover: #31A18B;
$color-lightviolet: #EDEAFC;
$color-primary: #31A18B;
$color-button-primary: #31A18B;
$color-button-primary-hover: #025049;
$color-button-secondary: #025049;
$color-button-secondary-hover: #31A18B;
$color-focus: #343434;

$color-black: #333;
$color-black40: #656666;
$color-black50: #7E8080;
$color-black60: #979999;
$color-black64: #9096A3;
$color-black80: #CACCCC;
$color-black90: #E3E6E6;
$color-black95: #F0F2F2;
$color-black98: #F5F6FA;
$color-white: #fff;

$color-background: $color-black98;
$color-text: $color-black;

// Fonts
$font-primary: Poppins;

// Radius
$border-radius: 8px;
$border-radius-small: 4px;
$border-radius-large: 16px;
$border-radius-x-large: 24px;

// Elevation
$elevate-color: rgb(40, 60, 81);
$elevate-1: 0 1px 1px 0 rgba($elevate-color, 0.06), 0 2px 6px 0 rgba($elevate-color, 0.15);
$elevate-4: 0 1px 1px 0 rgba($elevate-color, 0.06), 0 4px 12px 0 rgba($elevate-color, 0.15);
$elevate-6: 0 2px 3px 0 rgba($elevate-color, 0.06), 0 6px 24px 0 rgba($elevate-color, 0.15);

// Animation
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Link
$link__color: $color-button-primary-hover;

// Button
$button__font-weight: 500;

// Top Bar
$topbar__height: 72px;
$mobile-header__height: 64px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Backdrop
$modal-backdrop-bg: $color-black50;
$modal-backdrop-opacity: .8;
