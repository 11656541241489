@import "../../../styles/vars";
@import "../../../styles/mixins/type";

.otp-input-draft {
  font-size: 68px;
  letter-spacing: .5rem;
  position: relative;
  left: 0;
  width: 4.2em;
  margin: auto;

  &:before {
    content: '000000';
    position: absolute;
    color: $color-black90;
  }

  &_value {
    width: 5.3em;
  }

  &_value:before {
    display: none;
  }

  &__input {
    letter-spacing: .25em;
    position: relative;
    width: 100%;
    min-width: 3.5rem;
    border: 0;
    background: transparent;

    &:focus,
    &:active {
      outline: none;
    }
  }
}